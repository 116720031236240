$(function () {
    $('.form-confirm :checkbox').change(function () {
        if (this.checked) {
            $("#form-next").prop('disabled', false)
        } else {
            $("#form-next").prop('disabled', true)
        }
    })
    $("#form-next").click(function (e) {
        var hasError = validate();
        if (!hasError && !$('#form-page-2:visible').length) {
            e.preventDefault()
            confirmPageData();
            showPage(2);
            $("#form-next").attr('type', 'submit')
                .find('span').text('Send')
            $("#form-back").removeClass('d-none');
            $('html, body').animate({
                scrollTop: $("#contact-form").offset().top - $('.page-header').innerHeight()
            }, 100)
        }
    })
    $("#form-back").click(function (e) {
        e.preventDefault();
        showPage(1)
        $("#form-back").addClass('d-none');
        $("#form-next").attr('type', 'button')
            .find('span').text('Confirm')
    })

    $('.contact-form').submit(function(e) {
        console.log('qwe');
        if (!$('#form-page-2:visible').length) {
            e.preventDefault()
            console.log('qwe1');
            return false
        }
    })


    function showPage(page) {
        var $target = $(`#form-page-${page}`)
        $("#form-next").prop('disabled', true)
        $(".form-page:visible").removeClass('active')
        $target.addClass('active')
        $('.form-nav').toggleClass('page2', page == 2)
        if (page === 2) {
            $('.contact-desc').hide()
            $('.form-nav .form-label').hide()
        } else {
            $('.contact-desc').show()
            $('.form-nav .form-label').show()
        }
        setTimeout(() => {
            $("#form-next").prop('disabled', false)
        }, 100);
    }

    function confirmPageData() {
        $('input, textarea').each(function () {
            $(`#${this.name}-confirm`).text(this.value)
        })
    }

    function validate() {
        var hasError = false;
        $("[required]").each(function () {
            var $this = $(this)
            if ($this.val().trim() == "") {
                $this.addClass('error')
                hasError = true;
                return true // continue loop
            } else {
                $this.removeClass('error')
            }

            if ($this.attr('name') == 'email') {
                var isEmail = checkEmail($this.val().trim())
                if (!isEmail) {
                    hasError = true;
                    $this.addClass('error-email')
                }else{
                    $this.removeClass('error-email');
                }
            }
            if ($this.attr('name') == 'first_name_furi' || $this.attr('name') == 'last_name_furi' ) {
                var isKata = checkKata($this.val().trim());
                if (isKata) {
                    hasError = true;
                    $this.addClass('error-kana')
                }else{
                    $this.removeClass('error-kana');
                }
            }
        })
        return hasError;
    }

    function checkEmail(email) {
        var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return reg.test(email)

    }
    function checkKata(text) {
        var reg = /[\u30a0-\u30ff]/g
        return reg.test(text)
    }
})