$(function () {
    var $progressBar = $('.progress');
    var windowsize = $(window).width();
    if(windowsize <= 768 || $('.slide-interview .interview').length > 3){
        $('.slide-interview').length && $('.slide-interview').slick({
            infinite: false,
            slidesToShow: 1,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
            $progressBar.css('background-size', calc + '% 100%')
        });
    }else{
        $(".slide-controls").hide();
    }
    var hash = window.location.hash;
    if(hash){
        $('.tabs a').each(function (){
            if($(this).data('target') == hash){
                $(this).addClass('active')
                    .parent().siblings().find('a').removeClass('active')
                var $target = $($(this).data('target'));
                $target.addClass('active')
                    .siblings().removeClass('active');
                var target = $(this).closest(".body-container").offset().top;
                $(window).scrollTop(0);
                window.scrollTo({
                    top: target,
                    behavior: 'smooth',
                });
            }
        });
    }
    $('.page-footer nav a').on('click', function (e) {
        var newurl = $(this).prop('href');
        var url = window.location.href;
        if(newurl !== url){
            location.replace(newurl);
        }
        var hash = window.location.hash;
        if(hash){
            $('.tabs a').each(function (){
                if($(this).data('target') == hash){
                    $(this).addClass('active')
                        .parent().siblings().find('a').removeClass('active')
                    var $target = $($(this).data('target'));
                    $target.addClass('active')
                        .siblings().removeClass('active');
                    var target = $(this).closest(".body-container").offset().top;
                    $(window).scrollTop(0);
                    window.scrollTo({
                        top: target,
                        behavior: 'smooth',
                    });
                }
            });
        }
    })
    $(document).find(".hamburger a").on('click', function() {
        var newurl = $(this).prop('href');
        setTimeout(function (){
            var url = window.location.href;
            if(newurl !== url){
                location.replace(newurl);
            }
            var hash = window.location.hash;
            if(hash){
                    $('.tabs a').each(function (){
                        if($(this).data('target') == hash){
                            $(this).addClass('active')
                                .parent().siblings().find('a').removeClass('active')
                            var $target = $($(this).data('target'));
                            $target.addClass('active')
                                .siblings().removeClass('active');
                            var target = $(this).closest(".body-container").offset().top;
                            $(window).scrollTop(0);
                            window.scrollTo({
                                top: target,
                                behavior: 'smooth',
                            });
                        }
                    });
            }
        },500);
    });
    // tabs
    $('.tabs').on('click', 'a', function (e) {
        e.preventDefault();
        $(this).addClass('active')
            .parent().siblings().find('a').removeClass('active')

        var $target = $($(this).data('target'))
        $target.addClass('active')
            .siblings().removeClass('active')
    })
})