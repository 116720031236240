;(function($) {




// mega menu, hamburger menu
const Menu = {

	isOpen: false,
	isOpening: false,
	scroll_y: 0,

	$body: $(),

	$hamburger: $(),
	$hamburgerCover: $(),
	$hamburgerButton: $(),

	init: function() {

		const $footer = $('.page-footer');

		this.$body = $('.global-container');

		this.$hamburger = $('<div class="hamburger"></div>');
		this.$hamburgerCover = $('<div class="hamburger-cover"><span></span></div>');
		this.$hamburgerButton = $('<button type="button" class="hamburger-button"><span>Menu</span></button>');
		this.$hamburger.append($footer.find('nav').clone(), $footer.find('.foot').clone());
		$('body').append(this.$hamburger, this.$hamburgerCover, this.$hamburgerButton);

		const $elm = $footer.find('.js-duplicate');
		$footer.find('.col:last-child dl:first-child').prepend($elm.clone().addClass('pc-hidden'));
		$elm.addClass('sp-hidden');

		this.bindEvents();

	},

	bindEvents: function() {

		const _this = this;

		this.$hamburgerButton.on('click', function() {
			if (_this.isOpen) {
				_this.closeHamburger(false);
			} else {
				_this.openHamburger();
			}
		});
        $(document).find(".hamburger a").on('click', function() {
            if (_this.isOpen) {
                _this.closeHamburger(false);
            }
        });
	},

	openHamburger: function() {

		if (this.isOpening) return false;
		this.isOpening = true;

		const _this = this;

		this.scroll_y = $(window).scrollTop();
		this.$body.css({ position: 'fixed', top: -this.scroll_y });
		$(window).scrollTop(0);

		this.$hamburgerCover.addClass('is-open');
		this.$hamburgerButton.addClass('is-open').children().text('Close');
		this.$hamburger
			.addClass('is-open')
			.one('transitionend', function() {
				_this.isOpen = true;
				_this.isOpening = false;
			});

	},

	closeHamburger: function(resized) {

		if (this.isOpening) return false;
		this.isOpening = true;

		const _this = this;

		this.$hamburgerCover.removeClass('is-open');
		this.$hamburgerButton.removeClass('is-open').children().text('Menu');

		if (resized) {
			this.$hamburger.removeClass('is-open');
			release();
		} else {
			this.$hamburger.removeClass('is-open').one('transitionend', release);
		}

		function release() {

			_this.$body.css({ position: 'static', top: 0 });
			$(window).scrollTop(_this.scroll_y);

			_this.isOpen = false;
			_this.isOpening = false;

		}

	},

};




// page top button


const PageTopButton = {

  $button: $(),
  $ceil: $('<div id="page-top-ceil"></div>'),
  $floor: $('<div id="page-top-floor"></div>'),
	floorPos: 0,
  observer: null,

  init: function() {

    this.$button = $('.page-top');

    if (!('IntersectionObserver' in window)) {
      this.$button.addClass('floor');
      return;
    }

    $('body').append(this.$ceil, this.$floor);

    this.observer = new IntersectionObserver(this.onIntersect.bind(this), { threshold: 0 });

    //this.start();
		setTimeout(this.start.bind(this), 1000); // not good

  },

  start: function() {

		this.setBottomPos();

    this.observer.observe(this.$ceil.get(0));
    this.observer.observe(this.$floor.get(0));

  },

  setBottomPos: function() {

		this.floorPos = $('.page-footer > .inner').offset().top;
		const bottom = _isSP ? _windowWidth * .067 : 60;
		const buttonHeight = 50;

		this.$floor.css({ top: this.floorPos + bottom + buttonHeight });

  },

  onIntersect: function(entries, observer) {

		if (Menu.isOpen) {
			return;
		}

    let i, entry, id;

    for (i = 0; i < entries.length; i++) {

      entry = entries[i];
      id = entry.target.getAttribute('id');

      if (id == 'page-top-ceil') {
        if (entry.isIntersecting) {
          this.$button.addClass('is-shown');
        } else {
          this.$button.removeClass('is-shown');
        }
      } else {
        if (entry.isIntersecting) {
          this.$button.addClass('is-floor').css({ top: this.floorPos });
        } else {
          this.$button.removeClass('is-floor').css({ top: 'auto' });
        }
      }

    }

  }

};




// color and animate headings


const Heading = {


	animateTarget: '.js-animate',
	colorTarget: '.js-color',


	init: function() {

		this.split();

		if ($(this.animateTarget)[0]) {
			this.setDuration();
		}

		if ($(this.colorTarget)[0]) {
			this.setColor();
		}

		$(this.animateTarget + ', ' + this.colorTarget).addClass('is-ready');

	},


	split: function() {

		$(this.animateTarget + ', ' + this.colorTarget).each(function() {

			if ($(this).find('.l')[0]) {
				return true;
			}

			const from = $(this).html().trim().split('');
			let to = '', tag = false;

			$.each(from, function(i, val) {
				if (val == '>') {
					to += '>';
					tag = false;
				} else if (tag) {
					to += val;
				} else {
					if (val == '<') {
						to += '<';
						tag = true;
					} else if (val == ' ') {
						to += '<span class="l sp"></span>';
					} else if (val == '\xAD') {
						to += '<span class="l pc-hidden">-</span><br class="pc-hidden">';
					} else if (val != '\n') {
						to += '<span class="l">' + val + '</span>';
						//to += '<span class="l" style="transition-delay: ' + idx * .1 + 's;">' + val + '</span>';
						//idx++;
					}
				}
			});

			$(this).html(to);

		});

	},


	setDuration: function() {

		$(this.animateTarget).each(function() {

			const delay = ($(this).hasClass('head-bar')) ? .3 : 0;

			$(this).find('.l').each(function(idx) {
				$(this).css({ transitionDelay: idx * .03 + delay + 's' });
			});

		});

	},


	setColor: function() {

		$(this.colorTarget).each(function() {

			const $letters = $(this).find('.l');
			let left = [], width = 0;

			$letters.each(function() {

				let l = $(this).position().left;
				let w = $(this).width();

				left.push(l);

				if (l + w > width) {
					width = l + w;
				}

			});

			$letters.each(function(idx) {

				$(this).css({
					backgroundSize: width,
					backgroundPosition: -left[idx] + 'px 50%',
				});

			});

		});

	},


};




// scroll trigger


const Trigger = {


	target: '.js-trigger',
	triggeredClass: 'is-triggered',

	threshold: { down: .3, up: 0 },
	rewind: false,

	$elms: $(),
	elmsPos: [],
	prevPos: 0,
	interlock: [],


	init: function() {

		const _this = this;
		let waiting = false;

		this.$elms = $(this.target);

		this.$elms.each(function(idx) {

			const delay = $(this).data('delay');
			if (delay) {
				$(this).css({ transitionDelay: delay + 'ms' });
			}

			const il = $(this).data('interlock');
			if (il) {
				const tmp = il.split(':');
				let p = {};
				p.target = tmp[0];
				p.time = tmp[1];
				_this.interlock[idx] = p;
			}

		});

		this.setPos();

		window.addEventListener('scroll', function() {

			if (!waiting && !Menu.isOpen) {

				waiting = true;

				requestAnimationFrame(function() {
					_this.onScroll();
					waiting = false;
				});

			}

		}, _isPassiveSupported ? { passive: true } : false);

	},


	setPos: function() {

		const _this = this;
		this.elmsPos = [];

		this.$elms.each(function(idx) {

			let top = $(this).offset().top;
			const transform = $(this).css('transform');

			if (transform.substr(0, 6) == 'matrix') {
				top -= Number(transform.match(/[0-9]+/g)[5]);
			}

			_this.elmsPos.push({
				idx: idx,
				pos: {
					up: top - _windowHeight * (1 - _this.threshold.up),
					down: top - _windowHeight * (1 - _this.threshold.down),
				},
				triggered: false
			});

		});

		this.onScroll();

	},


	onScroll: function() {

		const scrollTop = $(window).scrollTop();
        
		const dir = this.prevPos > scrollTop ? 'up' : 'down';
		this.prevPos = scrollTop;

		if (dir == 'down') {

			for (let i = 0; i < this.elmsPos.length; i++) {  
                          
				if (!this.elmsPos[i].triggered && scrollTop > this.elmsPos[i].pos.down) {

					this.$elms.eq(this.elmsPos[i].idx).addClass(this.triggeredClass);

					if (this.interlock[i]) {
						const _this = this;
						setTimeout(function() {
							$(_this.interlock[i].target).addClass(_this.triggeredClass);
						}, this.interlock[i].time);
					}

					this.elmsPos[i].triggered = true;

				}

			}

		} else if (this.rewind) {

			for (let i = 0; i < this.elmsPos.length; i++) {

				if (this.elmsPos[i].triggered && scrollTop < this.elmsPos[i].pos.up) {

					$(this.interlock[i].target).removeClass(this.triggeredClass);

					if (this.interlock[i]) {
						const _this = this;
						setTimeout(function() {
							_this.$elms.eq(this.elmsPos[i].idx).removeClass(_this.triggeredClass);
						}, this.interlock[i].time);
					}

					this.elmsPos[i].triggered = false;

				}

			}

		}

	}


};




// marquee


const Marquee = {


	target: '.marquee',
	speed: 9, // sec to move 1000px
	prevTrainLen: [],


	init: function() {

		const _this = this;

		$(this.target).each(function(idx) {

			const txt = $(this).text();
			$(this).html('<div class="marquee-train"></div><div class="marquee-guide">' + txt + '</div>');

			_this.prevTrainLen[idx] = 0;

		});

		this.set();

	},


	set: function() {

		const _this = this;

		$(this.target).each(function(idx) {

			const $guide = $(this).children('.marquee-guide');
			const $train = $(this).children('.marquee-train');
			const carLen = $guide.innerWidth();
			const cars = Math.ceil(_windowWidth / carLen) + 1;
			const	trainLen = carLen * cars;

			if (trainLen != _this.prevTrainLen[idx]) {

				_this.prevTrainLen[idx] = trainLen;

				const txt = $guide.text();
				const car = '<span class="marquee-car"><span class="color">' + txt + '</span><span class="gray">' + txt + '</span></span>';

				$train.html(car.repeat(cars));

				let keyframes = [
					{ transform: 'translateX(0)' },
					{ transform: 'translateX(-' + carLen + 'px)' },
				];

				if ($(this).hasClass('reverse')) {
					keyframes = keyframes.reverse();
				}

				$train[0].animate(keyframes,
					{
						duration: carLen * _this.speed,
						easing: 'linear',
						iterations: Infinity,
					},
				);

			}

		});

	},


};




// smooth scroll


const SmoothScroll = {

	init: function() {

		const _this = this;

		$("a[href*='#'][href!='#']").on('click', function() {

			const href = $(this).attr('href');
			const tmp = href.split('#');
			const url = new URL(tmp[0], location.href);

			if (tmp[0] === '' || url.pathname == location.pathname) {

				const id = '#' + tmp[1];
				_this.go(id);

				return false;

			}

		});

	},

	go: function(id) {

		let top = 0;

		if ($(id)[0]) {
			top = $(id).offset().top - (_isSP ? _scrollOffsetSP : _scrollOffsetPC);
		} else {
			if (id != '#top') return;
		}

		window.scrollTo({
			top: top,
			behavior: 'smooth',
		});

	}

}




// passive support check


function isPassiveSupported() {

	let supported = false;

	try {
		window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
			get: function() {
				supported = true;
			}
		}));
	} catch(err) {}

	return supported;

}




// top page: first view


function playFirstView() {

	$('.top-fv').addClass('is-shown');

	setTimeout(function() {
		$('.top-fv p').addClass('is-triggered');
	}, 550);
	
}




// on ready


let _windowWidth = 0, _windowHeight = 0;
let _isSP = false, _isTB = false;
const _mediaQuerySp = window.matchMedia('(max-width: 639px)');
const _mediaQueryTb = window.matchMedia('(max-width: 767px)');
const _scrollOffsetPC = 150, _scrollOffsetSP = 120;
const _isPassiveSupported = isPassiveSupported();


$(function() {


	// init

	_windowWidth = $(window).width();
	_windowHeight = $(window).height();
	_isSP = _mediaQuerySp.matches;
	_isTB = _mediaQueryTb.matches;


	// hamburger menu

	Menu.init();


	// color and animate headings

	if ($(Heading.animateTarget + ', ' + Heading.colorTarget)[0]) {
		Heading.init();
	}


	// marquee

	if ($('.marquee')[0]) {
		Marquee.init();
	}


	// smooth scroll

	SmoothScroll.init();

	if (location.hash) {
		SmoothScroll.go(location.hash);
	}


	// page top button

	PageTopButton.init();


	// scroll trigger

	if ($(Trigger.target)[0]) {
		Trigger.init();
	}


	// top page: first view

	if ($('.top-fv')[0]) {
		playFirstView();
	}


	// on resize

	let resizeTimer = false;

	$(window).on('resize', function() {

		if (resizeTimer !== false) {
			clearTimeout(resizeTimer);
		}

		resizeTimer = setTimeout(function() {

			const w = $(window).width();
			_windowHeight = $(window).height();

			if (w != _windowWidth) {


				_windowWidth = w;

				const wasSP = _isSP, wasTB = _isTB;

				_isSP = _mediaQuerySp.matches;
				_isTB = _mediaQueryTb.matches;

				const brokenSP = !!(wasSP != _isSP);
				const brokenTB = !!(wasTB != _isTB);


				// hamburger menu

				if (!_isSP && brokenSP) {
					Menu.closeHamburger.apply(Menu, [true]);
				}


				// color headings

				if ($(Heading.colorTarget)[0]) {
					Heading.setColor.apply(Heading);
				}


				// marquee

				if ($('.marquee')[0]) {
					Marquee.set();
				}


				// page top button

				PageTopButton.setBottomPos.apply(PageTopButton);


				// scroll trigger

				if ($(Trigger.target)[0]) {
					Trigger.setPos();
				}


			}

		}, 500);

	});


});


})(jQuery);