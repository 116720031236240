$(function () {
    var $slider = $('.stories');
    var $progressBar = $('.progress');

    //gradient-line
    var $line = $(`<div class="gradient-line"></div>`);
    var $lineParent = $('.stories .story-item:first-child');
    $line.css('width', $lineParent.innerWidth() * $('.story-item').siblings().length + 50)
    $lineParent.append($line)

    $slider.length && $slider.slick({
        infinite: false,
        slidesToShow: 3,
        variableWidth: true,
        arrows: false,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var i = 3;
        var windowsize = $(window).width();
        if(windowsize <= 768){
            i = 1;
        }
        var calc = ((nextSlide) / (slick.slideCount - i)) * 100;
        $progressBar.css('background-size', calc + '% 100%')
    });
    
    $('.arrow-prev').click(function () {
        var $target = $("." + $(this).data('target'))
        $target.slick('slickPrev');
    });
    $('.arrow-next').click(function () {
        var $target = $("." + $(this).data('target'))
        $target.slick('slickNext');
    });

    var maxHeight;
    $('.viewmore').on('click', function () {
        var $this = $(this)
        var $target = $('.customer-text')
        var isOpen = $this.text() !== "View More";
        var minHeight = 220;
        if (!maxHeight) {
            $target.css('height', 'auto')
            maxHeight = $target.height();
            $target.css('height', minHeight)
        }
        $this.toggleClass('open', !isOpen).text(isOpen ? "View More" : "Close")

        $('.customer-text').animate({
            height: isOpen ? minHeight : maxHeight
        }, 300)
    })
})